.ticket-img {
    @apply m-auto mt-[-10rem]  max-w-max rounded-lg   border-2 md:mx-auto md:mt-[-15rem] xl:mt-[-22.9375rem];
    img {
        @apply rounded-lg bg-white;
    }
}

.month {
    @apply text-base font-normal uppercase text-[#BDBDBD] md:text-lg;
    &.month-name {
        @apply mx-3 cursor-default text-base md:mx-0;
    }
    &.active-month {
        @apply cursor-pointer text-lg font-medium text-primary underline;
    }
}

.day {
    @apply /*mb-[2.125rem]*/ flex cursor-pointer flex-col items-center justify-center gap-1  rounded-md px-2  py-1;
    border-radius: 10px;

    &.active-day {
        @apply text-white;
    }

    &.booked {
        @apply border-[#FF7C2ECC] bg-[#FF7C2ECC] text-white dark:border-[#f3894ccc] dark:bg-[#f3894ccc];
    }
}

.time {
    @apply mx-2 mt-10 flex max-w-[6.625rem]  cursor-pointer flex-col items-center gap-[0.9rem] border-2 border-primary px-4 py-4 text-primary hover:bg-primary-light xl:mx-0;

    border-radius: 10px;

    &.active-time {
        @apply bg-primary text-white;
    }
    &.booked {
        @apply border-[#FF7C2ECC] bg-[#FF7C2ECC] text-white;
    }
}

.TicketType {
    @apply flex min-w-fit items-center justify-between 
    rounded-t-2xl px-[1rem] pb-[1.8rem] pt-[1rem] xl:pb-[2.174375rem];
}

.ticketTypeDetails {
    @apply px-[1rem] py-[1.5rem] md:py-[2.174375rem];
}

.TicketSummary {
    @apply m-auto mt-[5.320625rem] rounded-2xl bg-[#FAFAFA] px-4  py-[3.3825rem] xl:px-[2.889375rem];
    border: 1px solid #eeeeee;
    box-shadow: 0px 4px 24px rgba(16, 24, 67, 0.1);
}

.day-scroll {
    scrollbar-color: #f00;
    background-color: #f00;
}
