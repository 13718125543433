@use "sass:meta";
.tag-selected {
    @apply bg-primary-light text-primary;
}
.link {
    @apply cursor-pointer;
}

.footer-item {
    transition: all 0.15s ease-in-out;
    @apply relative cursor-pointer text-sm text-gray-500 hover:text-blue-500 dark:text-gray-400 dark:hover:text-white;
}
.footer-item::after {
    content: "";
    position: absolute;
    width: 0%;
    height: 2px;
    border-radius: 20px;
    left: 50%;
    transform: translateX(-50%);
    bottom: -5px;
    background-color: #777;
    transition: all 0.2s ease-in-out;
    opacity: 0;
}
.footer-item:hover::after {
    @apply bg-blue-500 dark:bg-gray-400;
    width: 100%;
    opacity: 1;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    width: 100%;
    left: -10;
    height: 100%;
    right: 0;
    // display: none;
    opacity: 0;
    background-color: red;
    -webkit-appearance: none;

    @media (width: 765px) {
        left: -5%;
        right: 0;
    }
}

@include meta.load-css("../components/Layout/Navbar/Navbar.scss");
@include meta.load-css("../components/Layout/FooterMobile/footerMobile.scss");
@include meta.load-css("../components/Events/EventView.scss");
@include meta.load-css("../components/page_components/User/Tabs/Order/OrderTabs.scss");
@include meta.load-css("../components/Sections/AppsSection/AppsSection.scss");
@include meta.load-css("../components/Modal/Modal.scss");
@include meta.load-css("../components/page_components/LandingPage/HeroSection.scss");
@include meta.load-css("../components/page_components/auth/Auth.scss");
@include meta.load-css("../components/page_components/User/Profile.scss");
@include meta.load-css("../components/page_components/EventPage/EventPage.scss");
@include meta.load-css("../components/Booking/Ticket.scss");
@include meta.load-css("../components/page_components/SupportPage/Support.scss");
@include meta.load-css("../components/page_components/WhyUsPage/Whyus.scss");
@include meta.load-css("../components/page_components/User/Tabs/Account/AccountTab.scss");
@include meta.load-css("../components/PhoneInput/PhoneInput.scss");
@include meta.load-css("../components/page_components/EventPage/EventInfo.scss");
@include meta.load-css("../components/EventDetailsPage/EventHeroStyles.scss");
@include meta.load-css("../components/CreateYourEvent/CreateYourEvent.scss");
@include meta.load-css("../components/Animations/AnimatedText.scss");
@include meta.load-css("../components/Booking/Booking.scss");
