ol {
    list-style: none;
    counter-reset: orderedListCounter;
}
ol li {
    display: flex;
    gap: 0.5rem;

    // align-items: center;
    // justify-items: center;
    counter-increment: orderedListCounter;
}
ol li::before {
    content: counter(orderedListCounter) ".";
    font-weight: normal;
}

.event-policy-markdown {
    ul {
        list-style: square;
        list-style-type: disc;
        list-style-position: inside;
        counter-reset: unorderedListCounter;
        line-height: 1rem;
        padding: 12px;
    }

    ul li {
        display: flex;
        gap: 5px;
        margin-bottom: 0.5rem;
        counter-increment: unorderedListCounter;
    }

    ul li::before {
        content: "•";
        font-weight: bold;
        display: inline-block;
        // width: 1em;
        // margin-left: -0.8em;
    }
}
