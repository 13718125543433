.booking-container-scrollbar {
    --code-color: darkred;
    --code-bg-color: #aaaaaa;
    --code-font-size: 14px;
    --code-line-height: 1.4;
    --scroll-bar-color: darkGray;
    --scroll-bar-bg-color: #f6f6f6;

    scrollbar-width: thin;
    scrollbar-color: var(--scroll-bar-color) var(--scroll-bar-bg-color);
}

pre {
    color: var(--code-color);
    font-size: var(--code-font-size);
    line-height: var(--code-line-height);
    background-color: var(--code-bg-color);
}

.booking-container-scrollbar::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0.5);
}

/* for Chrome, Edge, and Safari */
.booking-container-scrollbar::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}

.booking-container-scrollbar::-webkit-scrollbar-track {
    background: var(--scroll-bar-bg-color);
}

.booking-container-scrollbar::-webkit-scrollbar-thumb {
    background-color: var(--scroll-bar-color);
    border-radius: 20px;
    border: 3px solid var(--scroll-bar-bg-color);
}

// .tickets-container-scrollbar {
//     -ms-overflow-style: none; /* Internet Explorer 10+ */
//     scrollbar-width: none; /* Firefox */
// }
// .tickets-container-scrollbar::-webkit-scrollbar {
//     width:
// }
