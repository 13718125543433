$blue-dark-700: #37469a;
$blue-dark-900: #06135a;

.linear-bg {
    background-image: linear-gradient(to right, $blue-dark-700, $blue-dark-900);
    height: 500px;
}

.support-layout {
    @apply mx-auto w-full max-w-7xl px-5;
}

.support-card {
    @apply flex h-full flex-col justify-between overflow-x-hidden rounded-2xl bg-[#F7F4F4] dark:bg-dark_background;

    .support-icon {
        @apply flex justify-center;
    }
    .support-content {
        // @apply pl-[2.864375rem] pr-[1.38625rem] mb-[3.896875rem] mt-[3.896875rem];
        @apply mb-[3.896875rem] mt-[3.896875rem] pl-[1.38625rem] pr-[1.38625rem];

        .support-title {
            @apply mb-[2.354375rem] mt-[2.35375rem] text-center text-2xl font-black text-[#504F4F] dark:text-white;
        }

        .support-text {
            @apply overflow-hidden overflow-ellipsis;
        }
    }
    .support-link {
        @apply bg-primary py-4 text-center text-lg font-bold text-white;
    }
}

.contactUs-form {
    // @apply bg-[#FAFAFA] rounded-2xl pt-[2.5625rem] max-w-[46.9rem] mx-auto mt-[-9.5rem] mb-[9.5rem];
    @apply mx-auto mb-[9.5rem] mt-[-3.5rem] max-w-[46.9rem] rounded-2xl bg-[#FAFAFA] pt-[2.5625rem] dark:bg-dark_background;
    box-shadow: 0px 4px 24px rgba(76, 95, 201, 0.1);
}
.contactUs-form-lable {
    @apply my-2 text-base font-bold text-[#393939] dark:text-dark_white md:my-4 md:text-xl;
}

// textarea {
//     @apply w-full dark:bg-dark_background border-2 border-gray-200 p-2 rounded outline-none bg-gray-100 focus:border-blue-400 focus:bg-white;
// }

.FAQ {
    @apply mx-auto mb-[2.42rem] overflow-hidden rounded-lg;
    border: 1px solid rgba(56, 56, 56, 0.2);

    .FAQ-title {
        // @apply bg-[#CCCCCC] pr-[2.5rem] pl-[3.66rem] py-[2.22rem] flex justify-between items-center gap-[11.5rem];
        @apply flex items-center justify-between bg-[#F6F4F4] p-2 dark:bg-dark_background;
    }

    .FAQ-text {
        @apply p-5 text-sm sm:text-base;
        // @apply pr-[2.5rem] pl-[3.66rem] py-[2.22rem] text-[1.75rem];
    }
}

.Terms-card {
    @apply flex flex-col items-center  gap-[1.625rem] rounded-lg px-[1.58rem] py-[2.28rem] xl:flex-row xl:items-start;
    background: rgba(204, 204, 204, 0.15);
    border: 1px solid rgba(56, 56, 56, 0.2);

    .Terms-number {
        @apply w-max rounded-full border-2  border-primary px-7 py-5 text-[1.375rem] font-bold text-primary;
    }
}

.Provider-support-card {
    // @apply bg-[#FAFAFA] max-w-[367px] px-[4.375rem] pt-12 pb-32 cursor-pointer hover:bg-[#2F3A72] hover:text-white;
    @apply my-10 max-w-[367px] cursor-pointer bg-[#FAFAFA] px-[2.375rem] pb-20 pt-12 hover:bg-[#2F3A72] hover:text-white;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.find-tickit-lable {
    @apply relative cursor-pointer text-[#504F4F] dark:text-[#ddd];
    &.active {
        @apply text-primary dark:text-blue-400;
        &::after {
            content: "";
            @apply absolute bottom-[-0.4375rem] left-0 h-5 w-full border-b-2 border-primary dark:border-blue-400;
        }
    }
}

.find-tickit-card {
    // @apply bg-[#FAFAFA] mt-[2.1875rem] px-[19.5rem] pt-14;
    @apply mt-[2.1875rem] flex items-center justify-center rounded-md bg-[#FAFAFA] dark:bg-dark_background;
    // width: 1140px;
    height: 400px;
    max-width: 1200px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.refund {
    @apply flex flex-col md:flex-row;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    max-width: 1200px;
    // min-width:767px
}
.refund-bg-img {
    @apply min-h-[18rem] w-full bg-no-repeat px-[2.125rem] pt-[5rem] text-white md:w-[30%] lg:min-h-[44.75rem] lg:pt-[8.75rem];
    background-image: url("/svg/refund-bg.svg");
}
.mailIcon {
    fill: white;
}
.terms-of-use-link {
    @apply fixed block  cursor-pointer text-[#949090] lg:text-[1.375rem];

    &.active {
        @apply text-primary;
    }
}

.terms-of-use-heading {
    @apply font-bold text-[#393939];
}

.terms-of-use-text {
    @apply lg:text-[0.9rem];
    line-height: 2;
    text-align: justify;
}
@media (max-width: 767px) {
    .refund-bg-img {
        // display:none;
        background-image: none;
        background-color: #fafafa;
    }
    .find-tickit-card {
        height: 350px;
    }
    .mailIcon {
        fill: #2741cc;
    }
}
