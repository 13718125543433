.img {
    background-image: url("/assets/images/Hero.png");
    @apply bg-no-repeat bg-cover fixed top-0 bottom-0  right-0 w-[37.5rem] justify-self-end -mt-20 hidden xl:block -z-50;
}

.Form {
    .form-title {
        @apply text-[1.5rem] xl:text-[2rem] font-bold text-center;
    }

    .form-text {
        @apply text-center mt-6;

        span {
            @apply inline-block text-primary;
        }
    }
}

.form-input {
    @apply mb-5;
    label {
        @apply block mb-1;
    }
    .input {
        @apply block w-full border-2  p-2 rounded outline-none bg-gray-100 focus:border-blue-500 focus:bg-white;
    }

    span {
        @apply hidden;
    }

    &.error {
        .input {
            @apply border-red-400 focus:border-red-400;
        }
        span {
            @apply block text-red-600;
        }
    }
}
