@tailwind base;
@tailwind components;
@tailwind utilities;

$primary: #2741cc;
$primary-light: rgba(39, 64, 204, 0.096);
$footer: #f8fafd;

@font-face {
    font-family: "Dubai";
    src: url("https://passticketsstorage.blob.core.windows.net/assets/Dubai-Regular.ttf")
        format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Dubai";
    src: url("https://passticketsstorage.blob.core.windows.net/assets/Dubai-Medium.ttf")
        format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Dubai";
    src: url("https://passticketsstorage.blob.core.windows.net/assets/Dubai-Light.ttf")
        format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Dubai";
    src: url("https://passticketsstorage.blob.core.windows.net/assets/Dubai-Bold.ttf")
        format("truetype");
    font-weight: 700;
    font-style: normal;
}

.dubai-font-class {
    font-family: "Dubai", sans-serif;
}

.noto-sans-class {
    font-family: "Noto Sans", sans-serif;
}

:root {
    font-size: 16px;
    scroll-padding-top: 5rem;
    color-scheme: only dark;
}

*,
*::before,
*::after {
    @apply box-border;
    scroll-behavior: smooth;
}

// Blur fix for safari on IPhone.
// * {
//     -webkit-backface-visibility: hidden;
//     -moz-backface-visibility: hidden;
//     -webkit-transform: translate3d(0, 0, 0);
//     -moz-transform: translate3d(0, 0, 0);
// }

html {
    overscroll-behavior: none;
}

img {
    @apply block max-w-full;
}

input,
textarea,
select,
button {
    @apply focus:outline-none;
}


button, button:focus, button:active {
    outline: none !important;
}

.reverse {
    direction: rtl;
}

.layout {
    @apply mx-auto w-full max-w-8xl px-5 md:px-10 xl:px-6;

    @media (min-width: 1280px) and (max-width: 1350px) {
        @apply mx-auto px-10;
    }
}

.custom-layout {
    @apply mx-auto w-full md:px-10 lg:max-w-7xl xl:px-0;

    @media (min-width: 1280px) and (max-width: 1350px) {
        @apply mx-auto px-10;
    }
}

.fade-anim img {
    animation: fadeAnim 0.5s ease-out;
}

@keyframes fadeAnim {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    @apply rounded-lg;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    @apply rounded-lg;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.scrollbar::-webkit-scrollbar-track {
    @apply rounded-lg;
}
.scrollbar::-webkit-scrollbar {
    width: 10px;
    background: lightgray;
    @apply rounded-lg;
}
.scrollbar::-webkit-scrollbar-thumb {
    background: blue;
    @apply rounded-lg;
}

.text-right {
    text-align: right;
    direction: rtl;
}

.verifyInput:not(:placeholder-shown) {
    border-color: #777;
}
.verifyInput-filled {
    display: block;
}
.verifyInput-not-filled {
    display: none;
}

.rai-circle {
    margin: 0 5px !important;
}

// Spin animation
.mySpin {
    animation-name: spin;
    animation-duration: 50000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.errorStyle {
    animation-name: errorShake;
    animation-duration: 200ms;
    animation-timing-function: linear;
    @apply border-red-500 dark:border-red-400;
}
@keyframes errorShake {
    0% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-10px);
    }
    50% {
        transform: translateX(0px);
    }
    75% {
        transform: translateX(10px);
    }
    100% {
        transform: translateX(0px);
    }
}

.text-link {
    @apply cursor-pointer text-xs font-bold text-primary-theme-200 hover:underline dark:text-primary-theme-100 md:text-sm;
}

.tilt-image {
    transition: transform 0.2s ease-out; /* You can adjust the timing and easing function as needed */
}

// Slider component
.slider {
    position: relative;
    margin: auto;

    @apply flex flex-col gap-5;
}

.slider-container {
    display: flex;
    transition: all 500ms ease-in-out;

    @apply self-end;
}

// .slider-image {
//     min-height: 100%;
// }

.slider-button {
    @apply flex h-8 w-8 cursor-pointer items-center justify-center border bg-gray-100 p-2 transition-all dark:border-transparent dark:bg-primary-theme-100 md:h-12 md:w-12 md:hover:bg-gray-200 md:hover:dark:bg-primary-theme-200 md:dark:hover:bg-opacity-70;
}

.fading {
    opacity: 0;
}

body.safari input,
body.safari select,
body.safari textarea {
    font-size: 16px !important;
}
