.footerMobile {
    display: flex;
    flex-direction: column;
    padding: 20px 32px;
    background-color: #f8fafd;

    .downloadApp {
        margin-bottom: 30px;
        h4 {
            margin-bottom: 45px;
            font-weight: 500;
            // font-size: 2px;
            text-align: center;
            letter-spacing: 1px;
        }

        .downloadAppIcon {
            @apply flex items-center justify-center gap-2;

            div:first-child {
                width: 100%;
            }
            div:nth-child(2) {
                width: 100%;
            }
        }
    }
    .links {
        .link {
            color: red;
            .heading {
                display: flex;
                margin-top: 40px;
                align-items: baseline;
                h4 {
                    display: block;
                    flex: 1;
                    font-weight: 500;
                }
                span {
                    display: block;
                    background-color: blue;
                    width: auto;
                }
            }
        }
    }
}
