.parallax-container {
    overflow-y: scroll;
    height: 100vh;
    scroll-snap-type: y mandatory;
}

.parallax-slide {
    scroll-snap-align: start;
    scroll-behavior: smooth;
    // height: 100vh;
}

.hero-scroll-container {
    @keyframes mymove {
        0% {
            top: 0px;
        }
        25% {
            top: 200px;
        }
        50% {
            top: 100px;
        }
        75% {
            top: 200px;
        }
        100% {
            top: 0px;
        }
    }
}

.hero-scroll-text {
    scroll-snap-align: start;
    scroll-behavior: smooth;
    // height: 100vh;
}
