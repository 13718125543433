.animated-text-container {
    height: 100%;
    // background-color: black;
}

@keyframes move {
    from {
        offset-distance: 0%;
    }

    to {
        offset-distance: 100%;
    }
}
.animated-text {
    offset: 0;
    // background-color: ;
    color: #fff;
}
