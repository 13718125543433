.card-info {
    @apply mx-[1rem] rounded-2xl bg-white p-0 dark:bg-dark_gray xl:mx-40;
    box-shadow: 0px 6px 40px rgba(6, 20, 36, 0.08);
}

.img-grid {
    @apply grid gap-1;
}

.EventImg {
    @apply h-[31.25rem] bg-cover bg-center bg-no-repeat;
}

.EventCardContainer {
    @apply flex flex-col gap-2 rounded-xl bg-white p-5 shadow-3xl dark:bg-dark_purple md:gap-3 md:dark:bg-dark_contact_us;
}

.EventInfo {
    // @apply xl:pr-[7.5625rem];
    // @apply max-w-xl;
    // li::marker {
    //   content: url("/svg/check.svg");
    //   flex-direction: "row-reverse";
    //   align-items: flex-start;
    // }

    li {
        @apply mx-5 mt-4 px-2;
    }
}
// .TicketCard {
//     @apply rounded-2xl p-5 xl:p-[1.5625rem];
// }

// @media (max-width: 767px) {
//     .TicketCard {
//         box-shadow: none;
//         background-color: white;
//     }
// }
