.event-hero-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    background: inherit;
    color: white;
}

.event-hero-text {
    backdrop-filter: blur(5px);
}
