.Profile {
    .Settings {
        @apply relative text-xl font-medium md:text-2xl;
    }
}

.TabLable {
    @apply flex items-end justify-center md:justify-start;
}

.tab {
    @apply relative cursor-pointer border-b border-gray-300 px-4 py-2 text-sm font-medium text-[#333333] transition-colors dark:text-white;

    &.active {
        @apply border-b-2 border-[#FD0058] text-[#FD0058] dark:border-[#FD3379] dark:text-[#FD3379];
    }
}

.Ticket {
    @apply rounded-lg py-2 pl-1 pr-6;
    box-shadow: 0px 4px 24px rgba(16, 24, 67, 0.1);
}

.filter {
    @apply cursor-pointer rounded-md bg-[#F9F9F9] px-[1.6875rem] py-[0.625rem] text-[#393939];
    &.active {
        @apply bg-primary text-white;
    }
}

.TicketForSale {
    @apply mb-[2.375rem] flex w-full  justify-around rounded-md border-l-[3rem]  border-primary bg-[#FAFAFA] py-7;

    .info-col {
        @apply flex flex-col gap-[1.875rem];

        span {
            @apply text-[0.875rem] text-[#393939];
        }
    }
}

.sell-method-title {
    @apply mb-[2.6875rem] mt-16 text-[1.125rem] text-[#393939];
}

.sell-method-form {
    @apply flex w-full items-center justify-center gap-[1.375rem];
}

// new profile
.profile-tab {
    @apply flex cursor-pointer items-center gap-2 text-gray-500 transition-colors dark:text-gray-200 md:hover:underline;

    &.active {
        @apply text-action-theme-400 dark:text-action-theme-200;
    }
}
