.scroll {
    overflow: auto;
}
.scroll::-webkit-scrollbar {
    width: 7px;
}

/* Track */
.scroll::-webkit-scrollbar-track {
    border-radius: 0px 0px 5px 5px;
}

/* Handle */
.scroll::-webkit-scrollbar-thumb {
    background: #495fdd;
    border-radius: 5px;
}

/* Handle on hover */
.scroll::-webkit-scrollbar-thumb:hover {
    background: rgb(34, 60, 211);
}
