// .eventGrid {
//     @apply grid gap-6 m-auto mb-8 justify-center row-end-auto;
//     grid-template-columns: repeat(auto-fill, minmax(22.9375rem, 1fr));
//   }

//   .sectionHeader {
//     @apply flex justify-between items-center mb-8;

//     .section-title {
//       @apply md:text-3xl text-lg font-bold;
//     }
//   }

//   .OrderCard {
//     @apply rounded-lg relative hover:scale-105 transform duration-300 hover:shadow-2xl cursor-pointer;
//     box-shadow: 0px 6px 40px rgba(6, 20, 36, 0.08);

//     .card-img {
//       img {
//         @apply rounded-t bg-cover h-36;
//       }
//     }

//     .card-content {
//       @apply p-4;
//       .card-title {
//         @apply text-lg font-semibold mb-4;
//       }

//       .card-text {
//         @apply mb-2 text-sm font-medium flex items-center text-gray-600;

//         span {
//           @apply inline-block mr-3;
//           &.reverse {
//             @apply ml-3;
//           }
//         }
//       }
//     }

//     .event-price {
//       @apply absolute top-4 left-4 bg-white py-1 px-4 rounded-lg capitalize text-sm;
//     }
//   }

// OrderCard

.cardRow {
    @apply grid gap-3 m-auto mb-8 justify-between row-end-auto;

    // @apply flex flex-row gap-6  m-auto mb-8;
    grid-template-columns: repeat(auto-fill, minmax(19.9375rem, 1fr));
}

.OrderCard {
    // @apply rounded-lg relative hover:scale-105 transform duration-300 hover:shadow-2xl cursor-pointer;
    @apply rounded-lg;
    box-shadow: 0px 6px 40px rgba(6, 20, 36, 0.08);

    .card-img {
        img {
            // @apply rounded-t bg-cover h-36;
            @apply rounded-t bg-cover h-[9.375rem];
        }
    }

    .card-content {
        @apply p-4;
        .card-title {
            @apply text-lg font-semibold my-3 mb-4 text-[#2E2E2E];
        }
        .card-status {
            @apply text-sm font-medium text-[#504F4F] text-[0.875rem];
        }

        .status {
            @apply px-20 my-10 w-3/4 text-[0.875rem];
        }

        .card-text-e {
            @apply mb-2 text-sm font-medium items-center text-gray-600 text-[0.875rem];
        }

        .card-text {
            @apply mb-2 text-sm font-medium flex items-center text-gray-600 text-[0.875rem];

            span {
                @apply inline-block mr-3;
                &.reverse {
                    @apply ml-3 mr-0;
                }
            }
        }
        .card-btns {
            @apply flex items-start justify-between;
        }
    }

    .event-price {
        @apply absolute top-4 left-4 bg-white py-1 px-4 rounded-lg capitalize text-sm;
    }
}
